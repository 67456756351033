.twitter-bs-wizard-tab-content {
    padding-top: 0 !important;
}

.margin-left-btn-add {
    margin-left: 5px !important;
}

.mobile-text-left {
    text-align: right;
}

@media (max-width: 991px) {
    .mobile-text-left {
        text-align: left;
        a {
            margin-left: 4px !important;
        }
    }
    .save-edit {
        position: relative !important;
    }
}
