#modalCropImage.container-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    .content-modal {
        display: contents;
        background: white;
        border-radius: 5px;
        padding: 1rem;
        -webkit-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
        box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.75);
        .container {
            max-height: 80%;
            height: 80%;
            overflow: scroll;
            .card-body {
                height: max-content;
                min-height: 500px;
            }
        }
        .App {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .crop-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 80px;
        }

        .controls {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 50%;
            transform: translateX(-50%);
            height: max-content;
            align-items: center;
            .MuiSlider-root {
                padding: 0rem;
            }
            .btn {
                margin-top: 0rem;
                margin-bottom: 0.5rem;
                margin-left: 0.5rem;
            }
        }
    }
}

@import "~react-image-gallery/styles/scss/image-gallery.scss";
